<template>
  <div class="iq-sidebar">
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <List :items="items" :open="true" :horizontal="horizontal" />
      </nav>
      <!-- {{ isSidebarOpen }} -->
    </div>
    <div class=" mb-4  mt-5 pl-2 policy_sidebar">
      <ul class="m-0 p-0 d-flex bottomItem pt-2">
        <!-- <li>{{ $t("Sidebar.1d9@about") }}</li> -->
        <li class="cursor-pointer">
          <router-link :to="{ path: '/PrivacyAndPolicy', query: { id: 1 } }">
            {{ $t("Sidebar.318@user_agreement") }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ path: '/PrivacyAndPolicy', query: { id: 2 } }">
            {{ $t("Sidebar.2d3@privacy_policy") }}
          </router-link>
        </li>
        <!-- <li>
          <router-link :to="{ path: '/PrivacyAndPolicy', query: { id: 3 } }">
            {{ $t("Sidebar.264@payment_policy") }}
          </router-link>
        </li> -->
        <li>JCI©{{ new Date().getFullYear() }}</li>
      </ul>
    </div>
  </div>
</template>
<script>
  import List from "../menus/ListStyle1";
  import bus from "@/Utils/Bus";

  export default {
    name: "SideBarStyle1",
    props: {
      homeURL: {
        type: Object,
        default: () => ({
          name: "layout1.dashboard",
        }),
      },
      items: {
        type: Array,
      },
      logo: {
        type: String,
        default: require("../../../assets/images/logo.png"),
      },
      horizontal: {
        type: Boolean,
      },
      toggleButton: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      List,
    },
    methods: {
      miniSidebar() {
        this.$emit("toggle");
      },
    },
    created() {
      if ($(".wrapper-menu").hasClass("open")) {
        // console.log($(".wrapper-menu").hasClass("open"));
        this.isSidebarOpen = false;
      } else {
        this.isSidebarOpen = true;
      }
      bus.$on("getSidebarOpen", (isShow) => {
        this.isSidebarOpen = isShow;
      });
    },
    data() {
      return {
        isSidebarOpen: true,
      };
    },
  };

</script>
<style lang="less" scoped>
  .iq-sidebar {
    height: calc(100% - 60px) !important;
    overflow-y: auto;

    a {
      color: #666666;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff !important;
    }

    &::-webkit-scrollbar {
      width: 0px;
    }

    &:hover {
      &::-webkit-scrollbar {
        width: 5px;
      }
    }

  }

  #sidebar-scrollbar {
    height: auto !important;
  }

  .bottomItem {
    flex-wrap: wrap;
    font-size: 13px;
    color: #666666;
    line-height: 26px;
    padding-left: 20px;
    border-top: 1px solid #eee;
    background-color: #fff;

    li {
      white-space: nowrap;
      margin-right: 21px;
      overflow: hidden;
    }
  }

  @media (min-width: 1500px) {
    body.sidebar-main .policy_sidebar {
      display: none;
    }
  }

  @media (min-width: 1300px) and (max-width: 1499px) {
    body.sidebar-main .policy_sidebar {
      display: block;
    }
  }

  @media (min-width: 1300px) and (max-width: 1499px) {
    .policy_sidebar {
      display: none;
    }
  }
</style>