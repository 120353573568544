<!--
 * @Author: your name
 * @Date: 2020-10-19 15:58:31
 * @LastEditTime: 2020-10-27 17:12:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GrandHyattc:\Users\25740\Desktop\jciweb\src\components\socialvue\footer\FooterStyle1.vue
-->
<template>
  <!-- Footer -->
  <footer class="bg-white iq-footer footerStyle">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9 d-flex align-items-center">
          <div class="row w-100">
            <div class="col-sm-2 d-flex justify-content-center">
              <img
                class="mr-2"
                src="../../../assets/images/logo-blue.png"
                alt=""
              />
              <div class="mr-4">©2020</div>
            </div>
            <ul class="list-inline mb-0 col-sm-10">
              <li class="list-inline-item"><a href="#">About</a></li>
              <li class="list-inline-item"><a href="#">User Agreement</a></li>
              <li class="list-inline-item"><a href="#">Privacy Policy</a></li>
              <li class="list-inline-item"><a href="#">Cookies Policy</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 d-flex justify-content-end">
          <div style="width: 100px">
            <select @change="selectLang" class="form-control birthdaySelect">
              <option v-for="(v, i) in languageList" :key="i">{{ v }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer END -->
</template>
<script>
export default {
  name: 'FooterStyle1',
  data () {
    return {
      languageList: ['英文', '中文']
    }
  },

  methods: {
    selectLang (v) {
      this.$i18n.locale = v.target.value == '英文' ? 'en' : 'zh_cn'
      // console.log(v.target.value);
      localStorage.setItem('lang', this.$i18n.locale)

    }
  }
}
</script>
<style lang="scss" scoped>
.footerStyle {
  .birthdaySelect {
    border: 0;
  }
}
</style>
