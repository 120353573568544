var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-collapse',{class:_vm.className,attrs:{"tag":"ul","visible":_vm.open,"id":_vm.idName,"accordion":_vm.accordianName}},_vm._l((_vm.items),function(item,index){return _c('li',{key:index,staticClass:"self_font",class:[
      item.is_heading
        ? 'iq-menu-title'
        : _vm.activeLink(item) && item.children
        ? 'active'
        : _vm.activeLink(item)
        ? 'active'
        : '',
      _vm.getActiveLinkStatus(item) ? 'active' : '' ]},[(item.is_heading)?_c('i',{staticClass:"ri-subtract-line"}):_vm._e(),(item.is_heading)?_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))]):_vm._e(),(!item.is_heading)?_c('router-link',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(item.name),expression:"item.name"}],class:[
        ("iq-waves-effect " + (_vm.activeLink(item) && item.children
            ? 'active'
            : _vm.activeLink(item)
            ? 'active'
            : '')) ],staticStyle:{"color":"#101541"},attrs:{"to":item.link}},[(item.is_icon_class)?_c('i',{class:item.icon}):void 0,_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])],2):_vm._e(),(item.children)?_c('List',{attrs:{"items":item.children,"open":item.link.name !== '' && _vm.activeLink(item) && item.children
          ? true
          : !!(item.link.name !== '' && _vm.activeLink(item)),"idName":item.name,"accordianName":("sidebar-accordion" + (item.class_name)),"className":("iq-submenu " + (item.class_name))}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }