<template>
  <div class="NavBarStyle">
    <!-- TOP Nav Bar -->
    <div class="iq-top-navbar">
      <div class="iq-navbar-custom">
        <nav class="navbar navbar-expand-lg navbar-light p-0">
          <div class="iq-navbar-logo d-flex justify-content-between">
            <span class="mx-1">
              <img :src="logo" class="logoImg img-fluid cursor-pointer" alt="logo" @click="$router.push('/')" />
            </span>
            <div class="iq-menu-bt align-self-center">
              <div class="wrapper-menu" @click="miniSidebar">
                <div class="main-circle"><i class="ri-menu-line"></i></div>
              </div>
            </div>
          </div>
          <div class="iq-search-bar">
            <div class="searchbox">
              <input type="text" class="text search-input" placeholder="Type here to search..." v-model="keyword"
                @keyup.enter="searchKeywords" />
              <span class="search-link-1 cursor-pointer" @click="searchKeywords"><i class="ri-search-line"></i></span>
            </div>
          </div>
          <b-navbar-toggle target="nav-collapse" class="mr-2">
            <i class="ri-menu-3-line"></i>
          </b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <ul class="navbar-nav ml-auto navbar-list">
              <!-- <li class="nav-item">
                <div class="exchange">
                  <select name="" id="">
                    <option value="">USD$</option>
                  </select>
                </div>
              </li> -->
              <li class="nav-item">
                <a href="javascript:;" class="iq-waves-effect position-relative"
                  @click="handleClickOpenPopup('language')">
                  <span class="modfiyBox" v-b-tooltip.hover title="Language"></span>
                  <i class="ri-global-line" style="font-size: 24px; color: #50b5ff"></i>
                </a>
                <div ref="language" class="iq-sub-dropdown iq-sub-dropdown-lang inlineBlock" v-if="currentType === 'language' && isShow">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">Language</h5>
                      </div>
                      <div class="language m-0 row p-2">
                        <span class="col">Language: </span>

                        <select @change="selectLang" v-model="selLang" class="selection col">
                          <option v-for="(v, i) in languageList" :key="i" :value="v.lang">
                            {{ v.language }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 头像及昵称 -->
              <li class="mr-4">
                <a href="javascript:;" class="
                    search-toggle
                    iq-waves-effect
                    d-flex
                    align-items-center
                  ">
                  <!-- <img
                    style="object-fit: cover"
                    :src="userInfo.avatar || defaultAvatar"
                    class="img-fluid rounded-circle mr-3"
                    alt="user"
                  /> -->
                  <el-avatar :size="55" :src="userInfo.avatar" @error="true">
                    <img :src="defaultAvatar" />
                  </el-avatar>
                  <div class="caption ml-3">
                    <h6 class="mb-0 line-height" style="
                        width: 15vh;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      ">
                      {{ userInfo.nick_name }}
                    </h6>
                  </div>
                </a>
                <div class="iq-sub-dropdown iq-user-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0">
                      <div class="bg-primary p-3 line-height">
                        <h5 class="mb-0 text-white line-height">
                          Hello {{ dataChat.nick_name }}
                        </h5>
                        <!-- <span class="text-white font-size-12">Available</span> -->
                      </div>
                      <!-- <div
                        class="shortcutFunction cursor-pointer"
                        @click="handleClickOpenPopup('goToLike')"
                      >
                        <h5 class="mb-0">Shortcuts</h5>
                        <div class="cursor-pointer moreOptions">
                          <img src="../../../assets/images/event/chevron-down.png" alt="" />
                          <div ref="goToLike" class="iq-sub-dropdown inlineBlock goToLike" style="width: 160px"
                            v-if="currentType === 'goToLike' && isShow">
                            <div class="iq-card shadow-none m-0">
                              <div class="iq-card-body p-0">
                                <a href="javascript:;" class="iq-sub-card" @click.stop="
                                No data
                                <a
                                  href="javascript:;"
                                  class="iq-sub-card"
                                  @click.stop="
                                    $router.push('/my-project'),
                                      (isShow = false)
                                  ">
                                  <div class="media align-items-center">
                                    <img src="../../../assets/images/headerIcon/1.png" alt="" />
                                    <div class="media-body ml-3">
                                      <h6 class="mb-0" style="margin-left: 4px">
                                        My Projects
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                                <a href="javascript:;" class="iq-sub-card" @click.stop="
                                    $router.push('/myotherorder'),
                                      (isShow = false)
                                  ">
                                  <div class="media align-items-center">
                                    <img src="../../../assets/images/headerIcon/1.png" alt="" />
                                    <div class="media-body ml-3">
                                      <h6 class="mb-0" style="margin-left: 4px">
                                        My Orders
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  href="javascript:;"
                                  class="iq-sub-card"
                                  @click.stop="
                                    $router.push('/my-award'), (isShow = false)
                                  "
                                >
                                  <div class="media align-items-center">
                                    <img
                                      src="../../../assets/images/headerIcon/2.png"
                                      alt=""
                                    />
                                    <div class="media-body ml-3">
                                      <h6 class="mb-0" style="margin-left: 4px">
                                        My Awards
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  href="javascript:;"
                                  class="iq-sub-card"
                                  @click.stop="
                                    $router.push('/my-donation'),
                                      (isShow = false)
                                  "
                                >
                                  <div class="media align-items-center">
                                    <img
                                      src="../../../assets/images/headerIcon/order.png"
                                      alt=""
                                    />
                                    <div class="media-body ml-3">
                                      <h6 class="mb-0" style="margin-left: 4px">
                                        My Donation
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  href="javascript:;"
                                  class="iq-sub-card"
                                  @click.stop="
                                    $router.push('/my-order'), (isShow = false)
                                  "
                                >
                                  <div class="media align-items-center">
                                    <img
                                      src="../../../assets/images/headerIcon/order.png"
                                      alt=""
                                    />
                                    <div class="media-body ml-3">
                                      <h6 class="mb-0" style="margin-left: 4px">
                                        My Orders
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <router-link to="/profile" class="iq-sub-card iq-bg-primary-hover">
                      </div> -->

                      <router-link to="/profile" class="iq-sub-card iq-bg-primary-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-file-user-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0" @click="myProfile">My Profile</h6>
                          </div>
                        </div>
                      </router-link>
                      <router-link to="/MyOtherOrder?model_type=2" class="iq-sub-card iq-bg-primary-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-shopping-cart-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">My Orders</h6>
                            <p class="mb-0 font-size-12">
                              <!-- Manage your account parameters. -->
                            </p>
                          </div>
                        </div>
                      </router-link>

                      <router-link to="/settings" class="iq-sub-card iq-bg-warning-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-warning">
                            <i class="ri-profile-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">Account settings</h6>
                            <p class="mb-0 font-size-12">
                              Manage your account parameters.
                            </p>
                          </div>
                        </div>
                      </router-link>
                      <!-- Time zone settings -->
                      <!-- <span @click="handletimeZoneSettings" class="iq-sub-card iq-bg-info-hover cursor-pointer">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-info">
                             <i class="ri-account-box-line"></i> 
                            <img src="../../../assets/images/public/timezone.png" alt="" />
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">Time zone settings</h6>
                            <p class="mb-0 font-size-12">
                              View different time zones.
                            </p>
                          </div>
                        </div>
                      </span> -->

                      <!-- logout -->
                      <div class="d-inline-block w-100 text-center p-3">
                        <a class="bg-primary iq-sign-btn cursor-pointer" role="button" @click="handleClickSignOut">Sign
                          out<i class="ri-login-box-line ml-2"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 好友消息 -->
              <li class="nav-item">
                <a class="iq-waves-effect" href="javascript:;" @click="handleClickOpenPopup('friendRequest')"><i
                    class="ri-group-line" v-b-tooltip.hover title="Friend Request"></i></a>
                <div ref="friendRequest" class="iq-sub-dropdown iq-sub-dropdown-large inlineBlock"
                  v-if="currentType === 'friendRequest' && isShow">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">
                          Friend Request<small class="badge badge-light float-right pt-1">{{ requestList.length
                            }}</small>
                        </h5>
                      </div>
                      <div class="iq-friend-request" v-for="(item, index) in requestList" :key="index"
                        v-show="index < 4">
                        <div class="
                            iq-sub-card iq-sub-card-big
                            d-flex
                            align-items-center
                            justify-content-between
                          ">
                          <div class="d-flex align-items-center">
                            <div class="">
                              <img class="avatar-40 rounded" :src="item.apply_user_info.avatar" />
                            </div>
                            <div class="media-body ml-3">
                              <h6 class="mb-0 applyClubDesc" :title="item.apply_user_info.nick_name">
                                {{ item.apply_user_info.nick_name }}
                              </h6>
                              <p class="mb-0 applyClubDesc">
                                <span v-if="item.group_info" style="color: #50b5ff">[{{ item.group_info.group_name
                                  }}]</span>
                                {{ item.type | typeFilter }}
                              </p>
                            </div>
                          </div>
                          <div class="d-flex align-items-center">
                            <a class="mr-3 btn btn-primary rounded" @click="confirmRequest(item.id)">Confirm</a>
                            <a @click="delRequest(item.id)" class="mr-3 btn btn-secondary rounded">Delete Request</a>
                          </div>
                        </div>
                      </div>
                      <div class="text-center" v-if="requestList.length > 4" @click="
                          $router.push('/friend-request'),
                            (isShow = false),
                            (currentType = '')
                        ">
                        <span class="mr-3 btn text-primary">View More Request</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 小铃铛消息 -->
              <li class="nav-item">
                <a href="javascript:;" class="iq-waves-effect position-relative"
                  @click="handleClickOpenPopup('smallBell')" v-if="system_message == 1">
                  <span class="modfiyBox" v-b-tooltip.hover title="All Notifications"></span>
                  <lottie class="pierceThrough" :option="
                      require('../../../assets/images/small/lottie-bell')
                    " id="lottie-beil" />
                  <span class="bg-danger dots"></span>
                </a>
                <a class="iq-waves-effect" href="javascript:;" @click="handleClickOpenPopup('smallBell')" v-else>
                  <i class="ri-notification-2-line" v-b-tooltip.hover title="All Notifications"></i>
                </a>
                <div ref="smallBell" class="iq-sub-dropdown inlineBlock" v-if="currentType === 'smallBell' && isShow">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">
                          All Notifications<small class="badge badge-light float-right pt-1">{{ notificationList.length
                            }}</small>
                        </h5>
                      </div>
                      <a href="javascript:;" class="iq-sub-card" v-for="(v, i) in notificationList" :key="i"
                        v-show="i < 4" @click="routerGoTo(v)">
                        <div class="media align-items-center">
                          <div class="user-img img-fluid">
                            <div v-if="v.type == 6" class="ri-notification rounded-circle avatar-40">
                              <i class="ri-notification-2-line"></i>
                            </div>

                            <img v-else :src="v.avatar || defaultAvatar" class="rounded-circle avatar-40" />
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">{{ v.nick_name }}</h6>
                            <small class="
                                float-right
                                font-size-12
                                text-overflow-single
                              " style="width: 30%">{{ v.diff_date }}</small>
                            <p class="mb-0 text-overflow-single" style="width: 145px">
                              {{ v.message }}
                            </p>
                          </div>
                        </div>
                      </a>
                      <div class="text-center" @click="
                          UnreadMessage(),
                            $router.push('/notification'),
                            (isShow = false),
                            (currentType = '')
                        ">
                        <span class="mr-3 my-3 btn text-primary">All Notifications</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 邮箱消息 -->
              <li class="nav-item dropdown">
                <a href="javascript:;" class="iq-waves-effect" @click="handleClickOpenPopup('email')"
                  v-if="messages.data && messages.msg_count">
                  <span class="modfiyBox" v-b-tooltip.hover title="All Messages"></span>
                  <lottie class="pierceThrough" :option="
                      require('../../../assets/images/small/lottie-mail')
                    " id="lottie-mail" />
                  <span class="bg-danger count-mail"></span>
                </a>
                <a class="iq-waves-effect" href="javascript:;" @click="handleClickOpenPopup('email')" v-else>
                  <i class="ri-mail-line" v-b-tooltip.hover title="All Messages"></i>
                </a>
                <div ref="emailInfo" class="iq-sub-dropdown iq-sub-dropdown-right inlineBlock" v-if="currentType === 'email' && isShow">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">
                          All Messages<small class="badge badge-light float-right pt-1">{{ messages.msg_count }}</small>
                        </h5>
                      </div>
                      <a href="javascript:;" class="iq-sub-card" v-for="(v, i) in messages.data" :key="i"
                        @click="viewMessage(v)">
                        <div class="media align-items-center">
                          <div>
                            <img class="avatar-40 rounded" :src="v.avatar" />
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">{{ v.nick_name }}</h6>
                            <div class="d-flex justify-content-between">
                              <small class="
                                  float-left
                                  font-size-12
                                  text-overflow-single
                                " style="width: 150px" v-if="!isShowType(v.last_msg)">{{ v.last_msg }}</small>
                              <small class="
                                  float-left
                                  font-size-12
                                  text-overflow-single
                                " style="width: 150px">
                                Share content with you
                              </small>
                              <b-badge variant="primary">{{
                                v.subscript
                                }}</b-badge>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- 三角形下拉选择 -->
            <ul class="navbar-list">
              <li>
                <!-- 图标 -->
                <!-- <a
                  href="#"
                  class="search-toggle iq-waves-effect d-flex align-items-center"
                >
                  <i class="ri-arrow-down-s-fill"></i>
                </a> -->
                <span class="modfiyBox" v-b-tooltip.hover title="Options"></span>
                <!-- 下拉 -->
                <div class="iq-sub-dropdown iq-user-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0">
                      <div class="bg-primary p-3 line-height">
                        <h5 class="mb-0 text-white line-height">
                          Hello {{ dataChat.nick_name }}
                        </h5>
                        <!-- <span class="text-white font-size-12">Available</span> -->
                      </div>
                      <!-- My Profile -->
                      <!-- <router-link
                        to="/profile"
                        class="iq-sub-card iq-bg-primary-hover"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-file-user-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">My Profile</h6>
                            <p class="mb-0 font-size-12">
                              View personal profile details.
                            </p>
                          </div>
                        </div>
                      </router-link> -->
                      <!-- Account settings -->
                      <router-link to="/settings" class="iq-sub-card iq-bg-warning-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-warning">
                            <i class="ri-profile-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">Account settings</h6>
                            <p class="mb-0 font-size-12">
                              Manage your account parameters.
                            </p>
                          </div>
                        </div>
                      </router-link>
                      <!-- Time zone settings -->
                      <span @click="handletimeZoneSettings" class="iq-sub-card iq-bg-info-hover cursor-pointer">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-info">
                            <!-- <i class="ri-account-box-line"></i> -->
                            <img src="../../../assets/images/public/timezone.png" alt="" />
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">Time zone settings</h6>
                            <p class="mb-0 font-size-12">
                              View different time zones.
                            </p>
                          </div>
                        </div>
                      </span>
                      <!-- logout -->
                      <div class="d-inline-block w-100 text-center p-3">
                        <a class="bg-primary iq-sign-btn cursor-pointer" role="button" @click="handleClickSignOut">Sign
                          out<i class="ri-login-box-line ml-2"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </b-collapse>
        </nav>
      </div>
      <!-- 时区设置弹窗 -->
      <b-modal id="timeZoneSettings" centered title="Time zone settings" hide-footer>
        <select class="form-control mb-3" v-model="timeArea" @change="handleChangeTimeArea">
          <option v-for="(v, k, i) in timeAreaList" :key="i" :value="v">
            {{ k }}
          </option>
        </select>
        <div class="row">
          <div class="col-md-3 mb-1">Current date and time:</div>
          <div class="col-md-9 mb-1">{{ dateTimeArea.current_time_area }}</div>
          <div class="col-md-3 mb-1">New date and time:</div>
          <div class="col-md-9 mb-1">{{ dateTimeArea.set_time_area }}</div>
        </div>
        <div class="mt-5 d-flex justify-content-end">
          <b-button variant="primary" class="btnOrange mr-2" @click="$bvModal.hide('timeZoneSettings')">
            Cancel
          </b-button>
          <b-button variant="primary" @click="handleClickSave">Save</b-button>
        </div>
      </b-modal>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
  import SideBarItems from "../../../FackApi/json/SideBar";
  import {
    mapActions,
    mapMutations,
    mapGetters
  } from "vuex";
  import Lottie from "../../../components/socialvue/lottie/Lottie";
  import defaultAvatar from "@/assets/images/public/user.png";
  import bus from "@/Utils/Bus";

  export default {
    name: "NavBarStyle1",
    props: {
      homeURL: {
        type: Object,
        default: () => ({
          name: "layout1.dashboard",
        }),
      },
      title: {
        type: String,
        default: "Dashboard",
      },
      logo: {
        type: String,
        default: require("../../../assets/images/logo-blue.png"),
      },
      horizontal: {
        type: Boolean,
        default: false,
      },
      items: {
        type: Array,
      },
    },
    mounted() {
      document.addEventListener("click", this.closeSearch, true);
      this.UnreadMessage();
      this.getLangList();
      this.getLang();
      // this.getSystemList()
      setInterval(() => {
        this.UnreadMessage();
      }, 60000);
    },
    components: {
      Lottie,
    },
    computed: {
      ...mapGetters({
        bookmark: "Setting/bookmarkState",
        dataChat: "ChatRoom/getDataChat",
        userInfo: "Public/getUserInfo",
      }),
    },
    filters: {
      typeFilter(val) {
        return val == 1 ?
          "Friend requests" :
          val == 2 ?
            "Apply to join the club" :
            val == 3 ?
              "Invite to join the club" :
              val == 4 ?
                "Apply for club administrator" :
                "";
      },
    },
    watch: {
      "dataChat.user_id": {
        deep: true,
        handler: async function (newData, oldData) {
          if (newData != undefined) {
            await this.getNewsSubscript();
          }
        },
      },
    },
    data() {
      return {
        system_message: 0,
        languageList: ["English"],
        selLang: "",
        defaultAvatar,
        sidebar: SideBarItems,
        globalSearch: "",
        showSearch: false,
        showMenu: false,
        keyword: "",
        requestList: [],
        messages: {
          data: [],
          msg_count: "",
        },
        notificationList: [],
        isShow: false,
        currentType: "",
        timeAreaList: [],
        timeArea: "",
        dateTimeArea: {
          current_time_area: "",
          set_time_area: "",
        },
        loadOnce: true,
      };
    },
    methods: {
      getLang() {
        if (localStorage.getItem('langType')) {
          this.selLang = localStorage.getItem('langType');
        } else {
          this.selLang = "en";
        }
      },
      async selectLang(v) {
        this.$i18n.locale = 'lang';
        // this.$i18n.locale = v.target.value == "en" ? "en" : "zh_cn";
        let res = await this.$http.getWebLanguageData({
          lang: v.target.value
        })
        localStorage.setItem('lang', res.data)
        localStorage.setItem('langType', v.target.value)
        this.$i18n.setLocaleMessage("lang", (JSON.parse(res.data)))

      },

      async getLangList() {
        let res = await this.$http.getWebLanguageList();
        this.languageList = res.data;
      },
      async myProfile() {
        await this.$router.push({
          path: "/profile",
        });
        location.reload();
      },
      ...mapMutations({
        CHANGE_CURRENT_CHAT: "ChatRoom/CHANGE_CURRENT_CHAT",
      }),
      isShowType(item) {
        if (typeof item === "string") {
          try {
            var obj = JSON.parse(item);
            if (typeof obj === "object" && obj && obj.share_type) {
              return true;
            } else {
              return false;
            }
          } catch (e) {
            return false;
          }
        } else {
          return false;
        }
      },
      async viewMessage(item) {
        // if (item.friend_id != undefined) {
        //   await this.clearFrienSubscript(item.friend_id);
        // } else if (item.group_id != undefined) {
        //   this.clearGroupSubscript(item.group_id);
        // }
        if (this.$route.path === "/chat") {
          if (item.friend_id) {
            this.$set(item, "user_id", item.friend_id);
          }
          this.CHANGE_CURRENT_CHAT({
            ...item,
            isSidebar: true,
          });
        } else {
          this.$router.push("/chat");
          if (item.friend_id) {
            this.$set(item, "user_id", item.friend_id);
          }
          this.CHANGE_CURRENT_CHAT({
            ...item,
            isSidebar: true,
          });
        }
        this.getNewsSubscript();
        this.isShow = false;
      },
      routerGoTo(item) {
        let whitelist = [1, 2, 3, 5];
        let event = [7,8];
        if (whitelist.includes(item.type)) {
          this.UnreadMessage();
          this.getSystemList();
          this.isShow = false;
          this.$router.push({
            path: `/newsfeeds/${item.id}`,
          });
        }else if(event.includes(item.type)){
          this.$router.push(`/event-detail?id=${item.join_id}&type=2&IsShowCheckout=0`);
        }
      },
      // 获取好友与群最新未读消息
      getNewsSubscript() {
        const data = {
          client_id: this.dataChat.client_id,
        };
        this.$http.getNewsSubscript(data).then((res) => {
          if (res.status == 200) {
            this.messages.data = res.data.data;
            this.messages.msg_count = res.data.msg_count;
          }
        });
      },
      // 系统消息列表
      getSystemList() {
        const data = {};
        this.$http.systemList(data).then((res) => {
          if (res.status == 200) {
            this.notificationList = res.data;
          }
        });
      },
      // 保存时区设置
      handleClickSave() {
        const data = {
          time_area: this.timeArea,
        };
        this.$http.editUser(data).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "Edited successfully",
              type: "success",
            });
          }
        });
      },
      // 更改时区选项
      handleChangeTimeArea() {
        this.loadOnce = false;
        this.getMyTimeArea(this.timeArea);
      },
      // 唤醒设置时区弹窗
      async handletimeZoneSettings() {
        this.loadOnce = true;
        this.$bvModal.show("timeZoneSettings");
        await this.getMyTimeArea();
        await this.getTimeArea();
      },
      // 获取时区
      getTimeArea() {
        const data = {};
        this.$http.getTimeArea(data).then((res) => {
          if (res.status == 200) {
            this.timeAreaList = res.data;
          }
        });
      },
      // 获取当前与设置时区时间
      getMyTimeArea(set_time_area) {
        const data = {
          set_time_area,
        };
        this.$http.getMyTimeArea(data).then((res) => {
          if (res.status == 200) {
            this.dateTimeArea = res.data;
            if (this.loadOnce) this.timeArea = res.data.my_time_area;
          }
        });
      },
      // 获取当前与设置时区时间
      getMyTimeArea1(set_time_area) {
        const data = {
          set_time_area,
        };
        this.$http.getMyTimeArea(data).then((res) => {
          if (res.status == 200) {
            this.dateTimeArea = res.data;
            this.timeArea = res.data.my_time_area;
          }
        });
      },
      handleClickOpenPopup(type) {
        this.currentType = type;
        this.isShow = !this.isShow;
        if (type === "smallBell" && this.isShow) this.UnreadMessage(), this.getSystemList();
        if (type === "friendRequest" && this.isShow) this.requestListFunc();
        if (type === "email" && this.isShow) this.UnreadMessage(), this.getNewsSubscript();
      },
      handleClickOnClose(e) {
        if (this.currentType === "friendRequest") {
          if (
            this.$refs.friendRequest &&
            !this.$refs.friendRequest.contains(e.target)
          ) {
            this.isShow = false;
            this.currentType = "";
          }
        } else if (this.currentType === "smallBell") {
          if (this.$refs.smallBell && !this.$refs.smallBell.contains(e.target)) {
            this.isShow = false;
            this.currentType = "";
          }
        } else if (this.currentType === "email") {
          if (this.$refs.emailInfo && !this.$refs.emailInfo.contains(e.target)) {
            this.isShow = false;
            this.currentType = "";
          }
        } else if (this.currentType === "goToLike") {
          if (this.$refs.goToLike && !this.$refs.goToLike.contains(e.target)) {
            this.isShow = false;
            this.currentType = "";
          }
        } else if (this.currentType === "language") {
          if (this.$refs.language && !this.$refs.language.contains(e.target)) {
            this.isShow = false;
            this.currentType = "";
          }
        }
      },
      // 用户退出
      async handleClickSignOut() {
        // console.log('退出登录');
        try {
          let res = await this.$http.signOut()
          if (res.status == 200) {
            let saveInfo = this.$getlocalStorage("saveInfo");
            localStorage.clear();
            this.$store.commit("Public/CHANGE_PAY_STATUS", false);
            if (saveInfo) {
              this.$setlocalStorage("saveInfo", saveInfo);
            }
            localStorage.setItem('once',1)
            window.location.reload()
            this.$router.push("/auth/sign-in1");
            // location.reload();
          }
        } catch (error) {
          this.$message.error(err.message)
        }
      },
      miniSidebar() {
        this.$emit("toggle");
        if ($(".wrapper-menu").hasClass("open")) {
          bus.$emit("getSidebarOpen", false);
        } else {
          bus.$emit("getSidebarOpen", true);
        }
      },
      openSearch() {
        this.showSearch = true;
        this.showMenu = "iq-show";
        this.globalSearch = "";
      },
      closeSearch(event) {
        let classList = event.target.classList;
        if (
          !classList.contains("searchbox") &&
          !classList.contains("search-input")
        ) {
          this.removeClass();
        }
      },
      removeClass() {
        this.showSearch = false;
        this.showMenu = "";
        this.globalSearch = "";
      },
      searchKeywords() {
        this.$router.push({
          path: "/search-result",
          query: {
            keyword: this.keyword,
          },
        });
      },
      // 获取未读消息
      UnreadMessage() {
        let token = localStorage.getItem("token");
        if (!token) return
        this.$http.UnreadMessage().then((res) => {
          if (res.status == 200) {
            this.system_message = res.data.system_message;
          }
        });
      },
      // 通知列表
      requestListFunc() {
        this.$http.userRequestList().then((res) => {
          this.requestList = res.data.data;
        });
      },

      confirmRequest(id) {
        this.$http
          .userRequestConfirm({
            id: id,
          })
          .then((res) => {
            if (res.status == 200) {
              this.requestListFunc();
            }
          });
      },
      delRequest(id) {
        this.$http
          .userRequestDel({
            id: id,
          })
          .then((res) => {
            if (res.status == 200) {
              this.requestListFunc();
            }
          });
      },
    },
    created() {
      document.addEventListener("click", this.handleClickOnClose, true);
      // this.getSystemList();
    },
    beforeDestroy() {
      document.removeEventListener("click", this.handleClickOnClose, true);
    },
  };

</script>

<style lang="scss" scope>
@media(max-width:600px){
  .logoImg{
    width:70px;
    margin-top: 10px;
  }
}
  .modfiyBox {
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .exchange {
    margin-top: 23px;
    margin-right: 10px;

    >select {
      border: 1px solid #999;
      color: #666;
    }
  }

  .NavBarStyle {
    .inlineBlock {
      border-radius: 5px !important;
      max-height: 440px;
      overflow-y: scroll;
      display: inline-block !important;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .iq-top-navbar {
      line-height: normal !important;
    }
  }

  .pierceThrough {
    position: relative;
    z-index: -1;
  }

  @media screen and (min-width: 992px) {
    #timeZoneSettings {
      .modal-dialog {
        min-width: 800px;
      }
    }
  }

  .shortcutFunction {
    background: #f5fbff;
    height: 74px;
    color: #3f414d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    font-size: 16px;
    color: #3f414d;

    .moreOptions {
      position: relative;

      .inlineBlock {
        position: absolute !important;
        right: -175px !important;
        top: -26px !important;
      }
    }
  }

  .ri-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5fbff;

    i {
      color: #50b5ff;
      font-size: 20px;
    }
  }

  .subscriptStyle {
    width: 15px;
    height: 15px;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-left: 10px;
  }

  .searchbox {
    .search-link-1 {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      color: rgb(80, 181, 255);
    }
  }

  .applyClubDesc {
    width: 165px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>