<template>
  <!-- Right Sidebar Panel Start-->
  <div style="height: 100%; background: #fff" :class="`right-sidebar-mini ` + miniClass">
    <div style="box-shadow: none" class="right-sidebar-panel p-0">
      <div class="iq-card shadow-none">
        <div class="iq-card-body p-0">
          <!-- chat!-->
          <div class="media-height iq-chat-data-block active-block" v-show="showChat" style="padding: 15px;">
            <!-- 搜索框 -->
            <h3 style="color: #50b5ff" class="createBtn">
              <div>Chats</div>
              <span class="mr-2" @click="handleCreatePopup">+</span>
            </h3>
            <div class="searchBox">
              <el-input type="text" clearable v-model="searchAll" suffix-icon="el-icon-search"
                placeholder="Search your group or friend" @change="searchList" />
            </div>
            <!-- 创建分组按钮 -->
            <!-- <div class="w-100 createBtn" @click="handleCreatePopup">
              <span class="mr-2">+</span> Create Group chat
            </div> -->
            <!-- 群列表 -->
            <div v-show="!showList">
              <tab-nav :tabs="true" id="myTab-two">
                <tab-nav-items :active="active == 'friends'" href="#friends" title="Friends" @click.native="
                    (active = 'friends'), (searchAll = ''), getUserFriend(),$emit('closeAllPop');
                  ">
                </tab-nav-items>
                <tab-nav-items :active="active == 'group'" href="#group" :title="$t('Posts.465@group')" @click.native="
                    (active = 'group'), (searchAll = ''), getUserGroup();$emit('closeAllPop');
                  " />
              </tab-nav>
              <tab-content>
                <tab-content-item :active="active == 'friends'" id="friends" aria-labelled-by="prerecording-tab-two">
                  <div class="rightChatListScoll">
                    <div v-for="(item, index) in friendsList" :key="`friend${index}`"
                      class="media align-items-center cursor-pointer mb-4" @click="handleClickChatItem(item);">
                      <div class="avatar mr-3">
                        <!-- 头像 -->
                        <!-- <img
                        :src="item.avatar || defaultAvatar"
                        class="rounded-circle avatar-50"
                      /> -->
                        <el-avatar :size="50" :src="item.avatar" @error="true">
                          <img src="@/assets/images/public/user.png" />
                        </el-avatar>
                        <!-- 是否在线小圆点 text-warning-->
                        <span class="avatar-status">
                          <i class="ri-checkbox-blank-circle-fill" :class="item.is_online == 1 ? 'text-success' : ''"
                            v-if="item.is_online == 1" />
                        </span>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 text-overflow-single" style="width: 130px">
                          <span :title="item.nick_name">{{
                            item.nick_name
                            }}</span>
                        </h6>
                        <p style="color: #999" class="mb-0">
                          {{ item.last_msg_time | filterDate }}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center" v-if="noFriends">
                      {{ $t("Posts.70e@no_friends") }}
                    </div>
                    <div style="
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                      " @click="getUserFriend" v-if="friendLost">
                      <img src="@/assets/images/reload.png" alt="" />
                      <a href="javascript:;">{{ $t("Posts.3f9@reload") }}</a>
                    </div>
                  </div>
                </tab-content-item>
                <tab-content-item :active="active == 'group'" id="group" aria-labelled-by="prerecording-tab-two">
                  <div class="rightChatListScoll">
                    <div v-for="(item, index) in groupList" :key="`group${index}`"
                      class="media align-items-center cursor-pointer mb-4" @click="handleClickChatItem(item)">
                      <div class="avatar mr-3">
                        <!-- 头像 -->
                        <img :src="item.avatar || defaultAvatar" class="rounded-circle avatar-50" />
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 text-overflow-single" style="width: 130px">
                          <span :title="item.group_name">{{
                            item.group_name
                            }}</span>
                        </h6>
                        <p style="color: #999" class="mb-0">
                          {{ item.last_msg_time | filterDate }}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center" v-if="noGroups">
                      {{ $t("Posts.747@no_groups") }}
                    </div>
                    <div style="
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                      " @click="getUserGroup" v-if="groupLost">
                      <img src="@/assets/images/reload.png" alt="" />
                      <a href="javascript:;">{{ $t("Posts.3f9@reload") }}</a>
                    </div>
                  </div>
                </tab-content-item>
              </tab-content>
            </div>
            <div v-show="showList">
              <div class="d-flex justify-content-center mt-5" v-if="noFriends&&noGroups">
                No Data
              </div>
              <div v-else class="rightChatListScoll mt-4">
                <div v-for="(item, index) in friendsList" :key="`friend${index}`"
                  class="media align-items-center cursor-pointer mb-4" @click="handleClickChatItem(item)">
                  <div class="avatar mr-3">
                    <!-- 头像 -->
                    <!-- <img
                        :src="item.avatar || defaultAvatar"
                        class="rounded-circle avatar-50"
                      /> -->
                    <el-avatar :size="50" :src="item.avatar" @error="true">
                      <img src="@/assets/images/public/user.png" />
                    </el-avatar>
                    <!-- 是否在线小圆点 text-warning-->
                    <span class="avatar-status">
                      <i class="ri-checkbox-blank-circle-fill" :class="item.is_online == 1 ? 'text-success' : ''"
                        v-if="item.is_online == 1" />
                    </span>
                  </div>
                  <div class="media-body ml-3">
                    <h6 class="mb-0 text-overflow-single" style="width: 130px">
                      <span :title="item.nick_name">{{ item.nick_name }}</span>
                    </h6>
                    <p style="color: #999" class="mb-0">
                      {{ item.last_msg_time | filterDate }}
                    </p>
                  </div>
                </div>
                <div v-for="(item, index) in groupList" :key="`group${index}`"
                  class="media align-items-center cursor-pointer mb-4" @click="handleClickChatItem(item)">
                  <div class="avatar mr-3">
                    <!-- 头像 -->
                    <img :src="item.avatar || defaultGroup" class="rounded-circle avatar-50" />
                  </div>
                  <div class="media-body ml-3">
                    <h6 class="mb-0 text-overflow-single" style="width: 130px">
                      <span :title="item.group_name">{{
                        item.group_name
                        }}</span>
                    </h6>
                    <p style="color: #999" class="mb-0">
                      {{ item.last_msg_time | filterDate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- toogle!-->
          <div class="right-sidebar-toggle bg-primary mt-3" @click="toggleMini">
            <i class="ri-arrow-left-line side-left-icon"></i>
            <i class="ri-arrow-right-line side-right-icon"><span class="ml-3 d-inline-block">{{
                $t("Posts.05f@close_menu")
                }}</span></i>
          </div>
        </div>
      </div>
    </div>
    <!-- 创建分组弹窗 -->
    <b-modal id="createGroupChat" centered :title="$t('Posts.9f4@create_group_chat')" hide-footer>
      <div v-loading="addModifyPopupLoading" class="row h-100 m-0">
        <div class="col-5 contentLeft border-right">
          <!-- 搜索框 -->
          <div class="mb-3 d-flex flex-grow-1 searchBox">
            <el-input type="text" suffix-icon="el-icon-search" clearable v-model="groupUser" @change="groupUserSearch"
              placeholder="Search Group" />
            <!-- <img @click="groupUserSearch" src="@/assets/images/course/search.png" alt="" /> -->
          </div>
          <!-- 好友列表 -->
          <ul class="p-0">
            <li class="d-flex justify-content-between mb-3" v-for="(item, index) in friendsList" :key="index">
              <div class="d-flex align-items-center">
                <div class="imgBox">
                  <img :src="item.avatar || defaultAvatar" alt="" />
                </div>
                <div style="width:130px" :title="item.nick_name" class="name text-truncate">{{ item.nick_name }}</div>
              </div>
              <div class="d-flex align-items-center">
                <input type="checkbox" class="form-check-input mr-3 mb-1" v-model="item.select"
                  @click="addUserList(item)" />
              </div>
            </li>
          </ul>
        </div>
        <div class="col-7 d-flex flex-column justify-content-between contentRight">
          <div>
            <div class="title">{{ $t("Posts.c24@please_check_the_con") }}</div>
            <div class="d-flex items-center" style="margin-bottom: 15px">
              <div style="width: 200px" class="d-flex align-items-center">
                {{ $t("Posts.07d@group_name") }}
              </div>
              <el-input clearable type="text" v-model="group_name" />
            </div>
            <!-- 选中的添加好友 -->
            <ul class="p-0">
              <li class="mb-3 d-flex justify-content-between" v-for="(item, i) in filterSelectFriend" :key="i">
                <div class="d-flex align-items-center">
                  <div class="imgBox">
                    <img :src="item.avatar || defaultAvatar" alt="" />
                  </div>
                  <div style="width:200px" class="name text-truncate" :title="item.nick_name">{{ item.nick_name }}</div>
                </div>
                <div class="d-flex align-items-center cursor-pointer" @click="removeSelect(item, i)">
                  <img src="@/assets/images/public/close-s.png" alt="" />
                </div>
              </li>
            </ul>
          </div>
          <div class="d-flex justify-content-end">
            <b-button variant="primary" class="btnOrange mr-2" @click="closeModel()">
              {{ $t("Posts.4cf@cancel") }}
            </b-button>
            <b-button variant="primary" @click="createGroup">{{
              $t("Posts.fca@confirm")
              }}</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
  <!-- Right Sidebar Panel End-->
</template>
<script>
  import loader from "../../../assets/images/logo.png";
  import darkLoader from "../../../assets/images/logo-white.png";
  import { mapActions, mapMutations, mapGetters } from "vuex";
  import defaultAvatar from "@/assets/images/public/user.png";
  import defaultGroup from "@/assets/images/public/circle_jci.png";

  export default {
    name: "RightSideBarStyle1",
    inject: ["reload"],
    data() {
      return {
        active: "friends",
        group_name: "",
        defaultAvatar,
        defaultGroup,
        checkboxList: [], // checkbox 选项
        friendsList: [], // 好友列表
        groupList: [], // 群聊列表
        miniClass: "",
        searchAll: "",
        showChat: true,
        animated: { enter: "zoomIn", exit: "zoomOut" },
        logo: loader,
        rtl: false,
        noFriends: false,
        noGroups: false,
        friendLost: false,
        groupLost: false,

        filterSelectFriend: [],
        filterId: [],
        groupUser: "",

        showList: false,

        addModifyPopupLoading: false//创建分组loading
      };
    },
    watch: {
      // user_id 不为空
      "dataChat.user_id": {
        deep: true,
        handler: async function (newData, oldData) {
          // console.log('dataChat.user_id', newData);
          if (newData != undefined) {
            await this.getUserGroup();
            await this.getUserFriend();
          }
        },
      },
      "dataChat.groupList": {
        deep: true,
        immediate: true,
        handler: function (newData, oldData) {
          this.groupList = this.dataChat.groupList;
          this.friendsList = this.dataChat.friendsList;
        },
      },
      groupList(v) {
        if (v.length > 0) {
          this.noGroups = false;
        } else if (v.length == 0) {
          this.noGroups = true;
        }
      },
      friendsList(v) {
        if (v.length > 0) {
          this.noFriends = false;
        } else if (v.length == 0) {
          this.noFriends = true;
        }
      },
    },

    props: {
      toggleClass: { type: String, default: "" },
    },
    filters: {
      filterDate(oldVal) {
        if (oldVal) {
          return oldVal.slice(0, 11);
        }
      },
    },
    computed: {
      ...mapGetters({
        dataChat: "ChatRoom/getDataChat",
      }),
      // 过滤出选中的好友
      // filterSelectFriend () {
      //   let friendsList = [];
      //   this.friendsList.forEach((v, i) => {
      //     if (v.select) {
      //       friendsList.push(v);
      //     }
      //   });
      //   return friendsList;
      // },
      hasIconSlot() {
        return !!this.$slots.icon;
      },
      hasOpenIconSlot() {
        return !!this.$slots.openIcon;
      },
      hasCloseIconSlot() {
        return !!this.$slots.closeIcon;
      },
    },

    methods: {
      ...mapMutations({
        CHANGE_DATA_CHAT: "ChatRoom/CHANGE_DATA_CHAT",
        CHANGE_CURRENT_CHAT: "ChatRoom/CHANGE_CURRENT_CHAT",
      }),
      ...mapActions({
        modeChange: "Setting/layoutModeAction",
      }),

    
      searchList() {
        if (this.searchAll) {
          this.showList = true;
        } else {
          this.showList = false;
        }
        this.getUserFriend();
        this.getUserGroup();
      },
      closeModel() {
        this.$bvModal.hide("createGroupChat");
        this.group_name = "";
        this.groupUser = "";
        this.groupUserSearch();
      },
      addUserList(item) {
        if (!item.select) {
          this.filterSelectFriend.push(item);
          this.filterId.push(item.user_id);
        } else {
          this.filterSelectFriend.forEach((v, i) => {
            if (v.user_id == item.user_id) {
              this.filterSelectFriend.splice(i, 1);
              this.filterId.splice(i, 1);
            }
          });
        }
      },
      // 邀请好友进群时搜索好友
      groupUserSearch() {
        //groupUser
        this.$http
          .userFriendList({ keyword: this.groupUser, type: 4 })
          .then((res) => {
            if (res.status == 200) {
              res.data.forEach((v) => {
                if (this.filterId.includes(v.user_id)) {
                  this.$set(v, "select", true);
                } else {
                  this.$set(v, "select", false);
                }
              });
              this.friendsList = res.data;
              // console.log(this.friendsList);
            }
          });
      },
      handleClickChatItem(item) {
         document
          .querySelector('#chat-user-detail-popup')
          ?.classList.remove('show')
        document.querySelector('#chat-user-info')?.classList.remove('show')
        document
          .querySelector('#chat-group-detail-popup')
          ?.classList.remove('show')
        if (this.$route.path === "/chat") {
          this.CHANGE_CURRENT_CHAT({ ...item, isSidebar: true });
        } else {
          this.$router.push("/chat");
          this.CHANGE_CURRENT_CHAT({ ...item, isSidebar: true });
        }
      },
      toggleMini() {
        this.rightSideBarMini = !this.rightSideBarMini;
        this.checkRightSideBar();
      },
      checkRightSideBar() {
        if (this.rightSideBarMini) {
          this.miniClass = "right-sidebar";
        } else {
          this.miniClass = "";
        }
      },
      // 创建分组
      handleCreatePopup() {
        // this.friendsList= [];
        this.$bvModal.show("createGroupChat");
        this.filterSelectFriend = [];
        this.filterId = [];
        this.friendsList =  this.dataChat.friendsList
      },
      // 创建群聊
      createGroup() {
        this.addModifyPopupLoading = true;
        let _this = this;
        let group_user_id = filterParams();
        const data = {
          group_user_id,
          group_name: this.group_name,
          invite_id: this.dataChat.user_id,
        };
        this.$http.createGroup(data).then((res) => {
          if (res.status == 200) {
            let { avatar, id, group_name } = res.data;
            if (this.$route.path === "/chat") {
              this.CHANGE_CURRENT_CHAT({
                ...{ avatar, group_id: id, group_name },
                isSidebar: true,
              });
            } else {
              this.$router.push("/chat");
              this.CHANGE_CURRENT_CHAT({
                ...{ avatar, group_id: id, group_name },
                isSidebar: true,
              });
            }
            this.group_name = "";
            this.checkboxList = [];
            this.getUserGroup();
            this.getUserFriend();
            this.$bvModal.hide("createGroupChat");
            this.groupUser = "";
            this.groupUserSearch();
            this.addModifyPopupLoading = false;
            this.closeAllPopup();
          }
        });
        
        function filterParams() {
          let params = [];
          _this.friendsList.forEach((v) => {
            if (v.select) {
              params.push(v.user_id);
            }
          });
          return params;
        }
      },

       closeAllPopup() {
        document.querySelector('#chat-user-detail-popup')?.classList.remove('show')
        document.querySelector('#chat-user-info')?.classList.remove('show')
        document.querySelector('#chat-group-detail-popup')?.classList.remove('show')
      },
      // 移除选中
      removeSelect(item, i) {
        this.$set(item, "select", false);
        this.filterSelectFriend.splice(i, 1);
        this.filterId.splice(i, 1);
        this.friendsList.forEach((v) => {
          if (v.user_id == item.user_id) {
            this.$set(v, "select", false);
          }
        });
      },
      // 获取用户好友列表并判断是否在线
      async getUserFriend() {
        const res = await this.$http.getUserFriend({
          keyword: this.searchAll,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        if (res.status == 200) {
          this.friendsList = JSON.parse(JSON.stringify(res.data));
          this.friendsList.forEach((v, i) => {
            this.$set(v, "select", false);
          });
          this.CHANGE_DATA_CHAT({ type: "friendsList", friendsList: res.data });
        } else {
          this.friendLost = true;
        }
      },
      // 获取群列表
      async getUserGroup() {
        const res = await this.$http.getUserGroup({
          keyword: this.searchAll,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        if (res.status == 200) {
          // console.log("group",res);
          this.CHANGE_DATA_CHAT({ type: "groupList", groupList: res.data });
          this.groupList = res.data;
        } else {
          this.groupLost = true;
        }
      },
    },
    mounted() {
      this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
        this.friendsList.forEach((v, i) => {
          v.select = false;
        });
      });
    },
  };
</script>
<style lang="scss">
  .right-sidebar-toggle.bg-primary.mt-3 {
    background: #EDBE38 !important;
  }

  .rightChatListScoll {
    max-height: calc(100vh - 239px);
    overflow-y: auto;
    // scrollbar-width: none;
    /* Firefox */
    // -ms-overflow-style: none;

    /* IE 10+ */
    // &::-webkit-scrollbar {
    //   display: none;
    /* Chrome Safari */
    // }
  }

  .nav-tabs .nav-item.show .nav-link,
  [dir="ltr"][mode="light"] .nav-tabs .nav-link.active {
    color: #333;
  }

  .nav-tabs .nav-item a {
    font-size: 17px;
    font-weight: 400;
  }

  .el-input--suffix .el-input__inner {
    /* border-radius: 10px; */
    height: 45px;
  }

  .searchBox {
    position: relative;

    img {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .createBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: #f5fbff;
      border-radius: 10px;
      font-size: 20px;
      color: #50b5ff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .imgBox {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  @media screen and (min-width: 992px) {
    #createGroupChat {
      .modal-dialog {
        max-width: 620px !important;
      }
    }
  }

  #createGroupChat {
    .modal-dialog {
      .modal-content {
        min-height: 520px !important;

        .modal-body {
          padding: 0;

          .contentLeft {
            padding: 1rem;
            padding-left: 22px;
            height: 460px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0 !important;
            }
          }

          .contentRight {
            padding: 1rem;
            padding-right: 30px;
            height: 460px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0 !important;
            }

            .title {
              font-size: 14px;
              color: #666666;
              margin-bottom: 15px;
              text-align: center;
            }
          }
        }
      }
    }
  }
</style>